// Generated by Framer (926e2eb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {hyhXRNte5: {hover: true}};

const cycleOrder = ["hyhXRNte5"];

const variantClassNames = {hyhXRNte5: "framer-v-1ayumzb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any; link?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "hyhXRNte5", tap: bOv4K8LJx, link: dgAFRtbgt, image: L4ObsrDnR, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "hyhXRNte5", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaps7y0nv = activeVariantCallback(async (...args) => {
if (bOv4K8LJx) {
const res = await bOv4K8LJx(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-tfmhj", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Link href={dgAFRtbgt}><motion.a {...restProps} className={`${cx("framer-1ayumzb", className)} framer-4zq3o4`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"hyhXRNte5"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"hyhXRNte5-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", ...toResponsiveImage(L4ObsrDnR)}} className={"framer-dxfvig"} data-framer-name={"Image"} data-highlight layoutDependency={layoutDependency} layoutId={"GXG0Amtu9"} onTap={onTaps7y0nv} transition={transition}/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-tfmhj [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tfmhj * { box-sizing: border-box; }", ".framer-tfmhj .framer-4zq3o4 { display: block; }", ".framer-tfmhj .framer-1ayumzb { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: wrap; gap: 0px; height: min-content; justify-content: flex-start; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 415px; }", ".framer-tfmhj .framer-dxfvig { aspect-ratio: 0.8 / 1; cursor: pointer; flex: none; height: var(--framer-aspect-ratio-supported, 500px); position: relative; width: 400px; }", ".framer-tfmhj .framer-v-1ayumzb .framer-1ayumzb { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tfmhj .framer-1ayumzb { gap: 0px; } .framer-tfmhj .framer-1ayumzb > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-tfmhj .framer-1ayumzb > :first-child { margin-top: 0px; } .framer-tfmhj .framer-1ayumzb > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 500
 * @framerIntrinsicWidth 415
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"FvqQPATRy":{"layout":["fixed","auto"]}}}
 * @framerVariables {"bOv4K8LJx":"tap","dgAFRtbgt":"link","L4ObsrDnR":"image"}
 */
const FramereuM2KbQtW: React.ComponentType<Props> = withCSS(Component, css, "framer-tfmhj") as typeof Component;
export default FramereuM2KbQtW;

FramereuM2KbQtW.displayName = "Featured Image";

FramereuM2KbQtW.defaultProps = {height: 500, width: 415};

addPropertyControls(FramereuM2KbQtW, {bOv4K8LJx: {title: "Tap", type: ControlType.EventHandler}, dgAFRtbgt: {title: "Link", type: ControlType.Link}, L4ObsrDnR: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramereuM2KbQtW, [])